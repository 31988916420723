import * as Sentry from "@sentry/astro";

Sentry.init({
  dsn: "https://2ce4ac08195323553a39ea034ce8a82f@o4506486495313920.ingest.sentry.io/4506486497280000",
  debug: false,
  environment: import.meta.env.PUBLIC_VERCEL_ENV,
  release: import.meta.env.PUBLIC_VERCEL_GIT_COMMIT_SHA,
  tracesSampleRate: 1,
  integrations: [new Sentry.BrowserTracing(), new Sentry.Replay()],
  replaysSessionSampleRate: 0.1,
  replaysOnErrorSampleRate: 1,
});
import { init } from 'astro/virtual-modules/prefetch.js';init()